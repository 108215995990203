import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
Vue.use(Router);
let routes = [
  {
      path: '/',
      redirect: '/student'
  },
  {
      path: "/rollCall",
      component: () => import("@/views/rollCall")
  },
  {
      path: "/login",
      component: () => import("@/views/login")
  },
  {
      path: "/student",
      component: () => import("@/views/student")
  },
  {
      path: "/project",
      component: () => import("@/views/project")
  },
  {
      path: "/report",
      component: () => import("@/views/reportList")
  },
  {
      path: "/finance",
      component: () => import("@/views/finance")
  },
  {
      path: "/financeReport",
      component: () => import("@/views/financeReport")
  },
  {
      path: "/tax",
      component: () => import("@/views/tax")
  },
  {
      path: "/social",
      component: () => import("@/views/social")
  },
  {
      path: "/zdz",
      component: () => import("@/views/zdz")
  },
  {
      path: "/td",
      component: () => import("@/views/td")
  },
  {
      path: "/ysd",
      component: () => import("@/views/ysd")
  }
]
const router = new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes,
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'] // 不需要验证的页面
  const authRequired = !publicPages.includes(to.path) // 是否需要验证
  const isLoggedIn = store.state.token // 假设你有一个状态来检查用户是否登录
 
  if (authRequired && !isLoggedIn) {
    return next('/login') // 如果需要验证并且用户未登录，重定向到登录页面
  }
 
  next() // 否则，继续导航
})
 
export default router