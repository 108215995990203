<template>
  <div id="app">
    <router-view></router-view> 
  </div>
</template>

<script>

export default {
  name: 'App',
  created() {
    this.$store.commit('SET_TOKEN', localStorage.getItem('token'))
  }
}
</script>

<style>

body{
  margin: 0;
  overflow: hidden;
}
html,body{
  height: 100%;
}
#app{
  height: 100%;
}
</style>
